<template>
	<v-sheet>
		<div class="d-flex justify-space-between">
			<div class="pa-3">
				<v-btn icon @click="goToYesterday">
					<v-icon color="primary">mdi-chevron-left</v-icon>
				</v-btn>
			</div>
			<div class="d-flex flex-grow-1 align-center py-2" @click.stop="dlgDate = true">
				<v-icon color="grey" left>mdi-calendar-month</v-icon>
				<div class="d-flex flex-column ml-3">
					<small class="caption">{{displayYear}}</small>
					<div class="body-1 font-weight-medium">{{displayMonthDate}} {{displayDayOfWeek}}</div>
				</div>
			</div>
			<div class="pa-3">
				<v-btn icon @click="goToTomorrow">
					<v-icon color="primary">mdi-chevron-right</v-icon>
				</v-btn>
			</div>
		</div>
		<v-divider></v-divider>
	
		<!--- Timesheet Project List --->
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-container>
			<v-list flat three-line v-if="projects.length">
				<v-list-item-group v-for="project in projects" :key="project.id">
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title>{{project.project.name}}</v-list-item-title>
							<v-list-item-subtitle><strong class="mr-2">建筑商:</strong>{{project.project.builder_name}}</v-list-item-subtitle>
							<v-list-item-subtitle><strong class="mr-2">项目开始日期:</strong>{{project.project.start_date}}</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn
								text
								color="primary"
								:to="{ name: 'timesheetProject', params: {id:project.id}}"
								>
								<v-icon small left>
									mdi-pencil
								</v-icon>
								编辑
							</v-btn>
						</v-list-item-action>
					</v-list-item>
					<v-divider></v-divider>
				</v-list-item-group>
			</v-list>
			<div v-else>
				<v-alert
					class="ma-3"
					text
					dense
					type="info"
					>
						<div class="body-2">这天还没有任何项目工作记录。<br >
						您可以点击下方 “+” 符号来添加新的项目记录。</div>
				</v-alert>
			</div>
			</v-container>
		</template>

		<!--- Datepicker Dialog ---->
		<v-dialog v-model="dlgDate" max-width="290">
			<v-card>
				<v-date-picker
					v-model="datePicker"
					:first-day-of-week="0"
					show-current
					locale="zh-cn"
					class="pb-6"
					>
				</v-date-picker>
				<v-card-actions class="justify-end">
					<v-btn text color="error" @click="dlgDate = false">
						取消
					</v-btn>
					<v-btn color="primary" elevation="0" @click="getSelectedDateProject">
						选择日期
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!--- Add Project Fab Button ---->
		<v-fab-transition>
			<v-btn
				color="primary"
				dark
				small
				fixed
				bottom
				right
				fab
				@click="dlgAddProject = true"
			>
				<v-icon>mdi-plus</v-icon>
			</v-btn>
		</v-fab-transition>
		<!--- Add Project Dialog --->
		<v-dialog v-model="dlgAddProject" max-width="480">
			<project-assigned-list
				:existingProjectIds="assignedProjects"
				@on-project-select="showAssignProjectConfirmation($event)">
				<template v-slot:title>
					选择当日的项目
				</template>
				<template v-slot:actions>
						<v-btn
						text
						color="error"
						elevation="0"
						@click="dlgAddProject = false"
					>
						取消
					</v-btn>
				</template>
			</project-assigned-list>
		</v-dialog>
		<v-dialog v-model="dlgAssignProjectConfirmation" max-width="320">
			<v-card>
				<v-card-title>添加项目</v-card-title>
				<v-card-text v-if="projectAssignedData">
					确定添加项目 {{projectAssignedData.name}} 到今天的工作日程吗？
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn
						text
						color="error"
						@click="dlgAssignProjectConfirmation = false">
						取消
					</v-btn>
					<v-btn
						class="primary"
						elevation="0"
						@click="assignProjectToTimesheet">
						添加
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!--- Snackbar --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</v-sheet>
</template>

<script>
import { format, parse, subDays, addDays } from 'date-fns';
import ProjectAssignedList from '../../components/Project/ProjectAssignedList';
import TimesheetService from '../../services/Timesheet';


export default {
	name: 'Timesheet',
	components: {
		ProjectAssignedList
	},
	computed: {
		displayYear(){
			return `${this.displayDate.getFullYear()}年`;
		},
		displayMonthDate(){
			return `${this.displayDate.getMonth() + 1}月${this.displayDate.getDate()}日`;
		},
		displayDayOfWeek(){
			const arr = new Array("星期日","星期一","星期二","星期三","星期四","星期五","星期六");
			return arr[this.displayDate.getDay()];
		}
	},
	data: () => ({
		dlgDate: false,
		dlgAddProject: false,
		dlgAssignProjectConfirmation: false,
		projects: [],
		assignedProjects: [],
		displayDate: new Date(),
		datePicker: new Date().toISOString().substr(0, 10),
		projectAssignedData: null,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		loading: false

	}),
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		}
	},
	methods: {
		getSelectedDateProject(){
			this.displayDate =	parse(this.datePicker, 'yyyy-MM-dd', new Date());
			this.getTimesheetOfThedate();
			this.dlgDate = false;
		},
		async getTimesheetOfThedate(){
			//get selected date by this.datePircker date.

			try {
				let projectDate = format(this.displayDate, 'dd/MM/yyyy');
				let param = {
					"date": projectDate
				}
				this.loading = true;
				let response = await TimesheetService.listTimeSheetGroup(param);

				if(response.data.statusCode === 200){
					this.projects = response.data.data.items;
					
					this.assignedProjects = this.projects.map(project=>{
						return project.project.id;
					})
				}
			} catch(error){

					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000);
			}
		},
		goToYesterday(){
			const yesterday = subDays(this.displayDate, 1);
			this.displayDate = yesterday;
			this.datePicker = yesterday.toISOString().substr(0, 10);
			this.loading = true;
			this.getTimesheetOfThedate();
		},
		goToTomorrow(){
			const tomorrow = addDays(this.displayDate, 1);
			this.displayDate = tomorrow;
			this.datePicker = tomorrow.toISOString().substr(0, 10);
			this.loading = true;
			this.getTimesheetOfThedate();
		},
		showAssignProjectConfirmation(project){
			this.projectAssignedData = project;
			this.dlgAssignProjectConfirmation = true;
		},
		async assignProjectToTimesheet(){

			this.dlgAddProject = false;
			let projectDate = format(this.displayDate, 'dd/MM/yyyy');
			let body = {
				"project_id": this.projectAssignedData.id,
				"date": projectDate
			}

			try {
				let response = await TimesheetService.createTimeSheetGroup(body);
				if(response.data.statusCode === 200){
					this.loading = true;
					this.dlgAssignProjectConfirmation = false;
					this.snackbar.message = `项目日程记录${response.data.message}`;
					this.snackbar.color = 'success';
					this.getTimesheetOfThedate();
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
				}

			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
			}

		}
	},
	created(){
		this.getTimesheetOfThedate();
	}
}
</script>
